class VlibasManager {
	constructor() {
		/**
		 * @private
		 * @type {boolean}
		 * - set default vlibras statement when page load
		 */
		this._currentState = false;

		/**
		 * @private
		 * @type {HTMLDivElement}
		 */
		this.vlibrasContainer = document.createElement('div'); // Inicializa vlibrasContainer

		/**
		 *
		 * @type {vlibrasCallback[]}
		 * @private
		 */
		this.vlibrasCallback = [];
	}

	/**
	 * @param {boolean} state - set vlibras state
	 */
	set currentState(state) {
		this._currentState = !!state;

		if (this.vlibrasContainer) {
			this.vlibrasContainer.className = this._currentState
				? 'enabled'
				: 'disabled';
		}

		this._deployCallbacks();
	}

	/**
	 * @returns {boolean} returns vlibras state
	 */
	get currentState() {
		return this._currentState;
	}

	deployVlibras() {
		// Configura o vlibrasContainer corretamente antes de usá-lo
		this.vlibrasContainer.setAttribute('vw', '');
		this.vlibrasContainer.classList.add(
			this._currentState ? 'enabled' : 'disabled'
		);

		// Cria o primeiro filho <div> com a classe "active" e atributo "vw-access-button"
		var firstChild = document.createElement('div');
		firstChild.setAttribute('vw-access-button', '');
		firstChild.classList.add('active');

		// Cria o segundo filho <div> com a classe "vw-plugin-wrapper"
		var secondChild = document.createElement('div');
		secondChild.setAttribute('vw-plugin-wrapper', '');

		// Cria o terceiro filho <div> com a classe "vw-plugin-top-wrapper"
		var thirdChild = document.createElement('div');
		thirdChild.classList.add('vw-plugin-top-wrapper');

		// Adiciona os filhos ao elemento pai
		this.vlibrasContainer.appendChild(firstChild);
		this.vlibrasContainer.appendChild(secondChild);
		secondChild.appendChild(thirdChild);

		// Adiciona o vlibrasContainer ao corpo do documento
		document.body.appendChild(this.vlibrasContainer);

		try {
			new window.VLibras.Widget()
		} catch (error) {
			console.log('Erro ao instanciar vlibras', error);
		}
	}

	/**
	 *
	 * @param {vlibrasCallback} callback
	 */
	runWhenChangeState(callback) {
		if (typeof callback == 'function') {
			callback(this._currentState);
			this.vlibrasCallback.push(callback);
		}
	}

	/**
	 * @private
	 */
	_deployCallbacks() {
		this.vlibrasCallback.forEach((cb) => {
			try {
				cb(this._currentState);
			} catch (error) {
				console.log('Erro ao executar callback vlibras', error);
			}
		});
	}
}

/**
 * @typedef {(state:boolean) => void} vlibrasCallback
 */

// INSTANCE VLIBRAS HERE
const vlibasManager = new VlibasManager();
vlibasManager.deployVlibras();

// aVlibras.addEventListener('click', function(e) {
// 	vlibasManager.currentState = true;
// 	toggleLibrasAndContrastContainer();
// });
